import Swiper, { Navigation, Pagination } from 'swiper';

export default function carousels() {
    const sliders = document.querySelectorAll('.js-mobile-slider');

    if (sliders) {
        sliders.forEach(function (slider) {
            const paginationEl = slider.querySelector('.swiper-pagination');
            const mobileCarusel = new Swiper(slider, {
                slidesPerView: 1.2,
                spaceBetween: 16,
                modules: [Pagination],
                pagination: {
                    el: paginationEl,
                    clickable: true,
                },
            });
        });
    }
    const relatedProducts = document.getElementById('related_products');

    if (relatedProducts) {
        const nextBtn = relatedProducts.querySelector('.swiper-button-next');
        const prevBtn = relatedProducts.querySelector('.swiper-button-prev');
        const paginationEle =
            relatedProducts.querySelector('.swiper-pagination');
        const relatedCarousel = new Swiper(relatedProducts, {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 16,
            modules: [Navigation, Pagination],
            pagination: {
                el: paginationEle,
                clickable: true,
            },
            navigation: {
                prevEl: prevBtn,
                nextEl: nextBtn,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 2.5,
                },
                400: {
                    slidesPerView: 1.5,
                },
                360: {
                    slidesPerView: 1.2,
                },
                300: {
                    slidesPerView: 1,
                },
            },
        });
    }
}
